<template>
  <div>
    <work-in-progress/>
  </div>
</template>

<script>
export default {
  components: {
    WorkInProgress: () => import('@/components/WorkInProgress.vue'),
  },
  props: {
    campaign: {
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/settings/_labels-tab.scss';
</style>
